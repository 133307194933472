import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import FilterResponseModel from '~/shared/model/FilterResponseModel'
import { FilterModel } from '~/shared/model/FilterModel'
import { HierarchyModel } from '~/shared/model/HierarchyModel'
import { PageModel } from '~/shared/model/PageModel'
import ReportResponseModel from '~/shared/model/ReportResponseModel'
import { ReportModel } from '~/shared/model/ReportModel'
import { FiltertService } from '~/shared/service/filter.service'
import { ReportService } from '~/shared/service/report.service'
import { MainStore } from '~/store/index'
import { ActiveFilterModel } from '~/shared/model/ActiveFilterModel'
import { GroupedFilterModel } from '~/shared/model/GroupedFilterModel'
import { FilterValueModel } from '~/shared/model/FilterValueModel'
import { DefaultFilterResponseModel } from '~/shared/model/DefaultFilterResponseModel'

@Module({ namespaced: true, name: 'ReportModule', dynamic: true, store: MainStore })
export default class ReportModule extends VuexModule {
  reports: ReportModel[] = []
  filters: FilterModel[] = []
  activeFilters: GroupedFilterModel[] = []
  selectedPageName: string = 'Summary'
  showFilter: boolean = true

  get getSelectedPartnerIds(): string[] {
    return this.activeFilters.filter((p) => p.table === 'Combined partner hierarchies')[0]?.values.map((p) => p.value)
  }

  get getSelectedProductIds(): string[] {
    return this.activeFilters.filter((p) => p.table === 'Combined product hierarchies')[0]?.values.map((p) => p.value)
  }

  get getSelectedDeclarantIds(): number[] {
    return this.activeFilters.filter((p) => p.table === 'Dimensions COMEXT Declarant')[0]?.values.map((p) => p.id)
  }

  get getSelectedPageName(): string {
    return this.selectedPageName
  }

  get getShowFilter(): boolean {
    return this.showFilter
  }

  @Mutation
  SetShowFilter(pageName: string): boolean {
    switch (pageName) {
      case '/contact':
      case '/contact/':
      case '/disclaimer':
      case '/disclaimer/':
      case '/introduction':
      case '/introduction/':
        this.showFilter = false
        return false
      default:
        this.showFilter = true
        return true
    }
  }

  @Mutation
  SetSelectedPageName(pageName: string): void {
    this.selectedPageName = pageName
  }

  @Mutation
  addDefaultFiltersToStore(defaultFilters: DefaultFilterResponseModel[]) {
    this.activeFilters = []
    defaultFilters.forEach((defFilter) => {
      this.activeFilters.push(
        new GroupedFilterModel({
          table: defFilter.table,
          column: defFilter.column,
          operator: defFilter.operator,
          values: defFilter.values,
        })
      )
    })
  }

  @Mutation
  addFiltersToStore(filters: FilterResponseModel[]) {
    this.filters = [
      ...filters.map((rep) => {
        return new FilterModel({
          name: rep.name,
          displayName: rep.displayName,
          filtervisual: rep.filterVisual,
          operation: rep.operation,
          table: rep.tabel,
          defaultValue: rep.defaultValue,
          values: rep.values.map((valueRep) => {
            return new HierarchyModel({
              value: valueRep.value,
              anotherLevel: valueRep.anotherLevel,
              columnName: valueRep.columnName,
              valueId: valueRep.valueId,
              levels: valueRep.level.map((level1) => {
                return new HierarchyModel({
                  value: level1.value,
                  anotherLevel: level1.anotherLevel,
                  columnName: level1.columnName,
                  valueId: level1.valueId,
                  levels: level1.level.map((level2) => {
                    return new HierarchyModel({
                      value: level2.value,
                      anotherLevel: level2.anotherLevel,
                      columnName: level2.columnName,
                      valueId: level2.valueId,
                      levels: level2.level.map((level3) => {
                        return new HierarchyModel({
                          value: level3.value,
                          anotherLevel: level3.anotherLevel,
                          columnName: level3.columnName,
                          valueId: level3.valueId,
                          levels: level3.level.map((level4) => {
                            return new HierarchyModel({
                              value: level4.value,
                              anotherLevel: level4.anotherLevel,
                              columnName: level4.columnName,
                              valueId: level4.valueId,
                              levels: level4.level.map((level5) => {
                                return new HierarchyModel({
                                  value: level5.value,
                                  anotherLevel: level5.anotherLevel,
                                  columnName: level5.columnName,
                                  valueId: level5.valueId,
                                  levels: level5.level.map((level6) => {
                                    return new HierarchyModel({
                                      value: level6.value,
                                      anotherLevel: level6.anotherLevel,
                                      columnName: level6.columnName,
                                      valueId: level6.valueId,
                                    })
                                  }),
                                })
                              }),
                            })
                          }),
                        })
                      }),
                    })
                  }),
                })
              }),
            })
          }),
        })
      }),
    ]
  }

  @Mutation
  addReportsAndPagesToStore(reportLijst: ReportResponseModel[]) {
    this.reports = [
      ...reportLijst.map((rep) => {
        return new ReportModel({
          name: rep.name,
          description: rep.description,
          pages: rep.pages.map((page) => {
            return new PageModel({
              name: page.name,
              active: page.active,
              icon: page.icon,
              order: page.order,
            })
          }),
        })
      }),
    ]
  }

  @Mutation
  updateActiveFilterForRadioButton(filter: ActiveFilterModel) {
    const columnExists = this.activeFilters.filter((p) => p.table === filter.table && p.column === filter.column)
    if (columnExists.length > 0) {
      // delete if exists
      const index = this.activeFilters.indexOf(columnExists[0])
      this.activeFilters.splice(index, 1)
    }

    // add new filter
    this.activeFilters.push(
      new GroupedFilterModel({
        column: filter.column,
        operator: filter.operator,
        table: filter.table,
        values: [new FilterValueModel({ value: filter.value })],
      })
    )
  }

  @Mutation
  updateCombinedPartnerHierarchiesSelection(selectie: HierarchyModel[]) {
    const selectedTable = this.activeFilters.filter((p) => p.table === 'Combined partner hierarchies')

    // Add filter group + Value
    if (selectedTable.length < 1) {
      this.activeFilters.push(
        new GroupedFilterModel({
          column: 'NAME2',
          operator: 'IN',
          table: 'Combined partner hierarchies',
          values: [],
        })
      )

      const selectedNewTable = this.activeFilters.filter((p) => p.table === 'Combined partner hierarchies')
      selectie.forEach((selectedValue) => {
        selectedNewTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
    // Add value
    else {
      selectedTable[0].values.splice(0, selectedTable[0].values.length)
      selectie.forEach((selectedValue) => {
        selectedTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
  }

  @Mutation
  updateCombinedProductHierarchiesSelection(selectie: HierarchyModel[]) {
    const selectedTable = this.activeFilters.filter((p) => p.table === 'Combined product hierarchies')
    // Add filter group + Value
    if (selectedTable.length < 1) {
      this.activeFilters.push(
        new GroupedFilterModel({
          column: 'LEVEL8',
          operator: 'IN',
          table: 'Combined product hierarchies',
          values: [],
        })
      )

      const selectedNewTable = this.activeFilters.filter((p) => p.table === 'Combined product hierarchies')
      selectie.forEach((selectedValue) => {
        selectedNewTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
    // Add value
    else {
      selectedTable[0].values.splice(0, selectedTable[0].values.length)
      selectie.forEach((selectedValue) => {
        selectedTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
  }

  @Mutation
  updateDimensionsCOMEXTDeclarantSelection(selectie: HierarchyModel[]) {
    const selectedTable = this.activeFilters.filter((p) => p.table === 'Dimensions COMEXT Declarant')

    // Add filter group + Value
    if (selectedTable.length < 1) {
      this.activeFilters.push(
        new GroupedFilterModel({
          column: 'DeclarantWithCode',
          operator: 'IN',
          table: 'Dimensions COMEXT Declarant',
          values: [],
        })
      )

      const selectedNewTable = this.activeFilters.filter((p) => p.table === 'Dimensions COMEXT Declarant')
      selectie.forEach((selectedValue) => {
        selectedNewTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
    // Add value
    else {
      selectedTable[0].values.splice(0, selectedTable[0].values.length)
      selectie.forEach((selectedValue) => {
        selectedTable[0].values.push(
          new FilterValueModel({
            id: selectedValue.valueId,
            value: selectedValue.value,
          })
        )
      })
    }
  }

  @Action
  async getReportsAndPages(): Promise<void> {
    await ReportService.GetReports().then((resp) => {
      this.addReportsAndPagesToStore(resp)
    })
  }

  @Action
  async getFilters(): Promise<void> {
    await FiltertService.GetFilters().then((resp) => {
      this.addFiltersToStore(resp)
    })
  }

  @Action
  async getDefaultFilterValues(): Promise<void> {
    await FiltertService.GetDefaultFilters().then((resp) => {
      this.addDefaultFiltersToStore(resp)
    })
  }
}
