export class ApiUrl {
  /**
   * ApiUrl() gives the endpoint for the web api
   */
  public static webapiurl() {
    switch (window.location.host) {
      case 'tradeukraineconflict.wecr.wur.nl':
        return 'https://tradeukraineconflict.wecr.wur.nl/WebApi'
      case 'acc.tradeukraineconflict.wecr.wur.nl':
        return 'https://acc.tradeukraineconflict.wecr.wur.nl/WebApi'
      case 'tst.tradeukraineconflict.wecr.wur.nl':
        return 'https://tst.tradeukraineconflict.wecr.wur.nl/WebApi'
      case 'agrotradeukraine.wecr.wur.nl':
        return 'https://agrotradeukraine.wecr.wur.nl/WebApi'
      case 'acc.agrotradeukraine.wecr.wur.nl':
        return 'https://acc.agrotradeukraine.wecr.wur.nl/WebApi'
      case 'tst.agrotradeukraine.wecr.wur.nl':
        return 'https://tst.agrotradeukraine.wecr.wur.nl/WebApi'
      default:
        return 'http://localhost:50256'
    }
  }
}