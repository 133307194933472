




import * as crypto from 'crypto'
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type UserInfo = { name: string; email: string }

@Component({
  components: {},
})
export default class LogoOnlyHeader extends Vue {
  /* Props */
  /* Events/Listeners */
}
